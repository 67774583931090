if (document.body.classList.contains("page-template-produkt-level-2")) {
    const storageKey = "listScrollPosition";

    let scrollEl = document.querySelector(".lvl2-wrap .list-wrap .list");
    scrollEl.addEventListener("click", () => {
        localStorage.setItem(storageKey, scrollEl.scrollTop);
    })
    let scrollElArrowUp = document.querySelector('.list-wrap .up');
    let scrollElArrowDown = document.querySelector('.list-wrap .down');


    let maybeHideListArrows = () => {
        scrollElArrowUp.style.opacity = scrollEl.scrollTop == 0 ? 0 : 1;
        scrollElArrowDown.style.opacity = scrollEl.scrollTop + scrollEl.clientHeight == scrollEl.scrollHeight ? 0 : 1;
    }


    scrollEl.addEventListener("scroll", maybeHideListArrows)

    maybeHideListArrows()

    document.addEventListener("DOMContentLoaded", () => {
        let pos = localStorage.getItem(storageKey);
        if (pos != null) {
            scrollEl.scrollTo({ top: pos })
        }
    })


    let variantsEl = document.querySelector('.lvl2-wrap .variants');
    let arrows = document.querySelectorAll('.lvl2-wrap .variants .up, .lvl2-wrap .variants .down')
    let maybeHideVariantsArrows = () => {
        if (variantsEl) {
            if (variantsEl.scrollHeight > variantsEl.clientHeight) {
                arrows.forEach(el => el.classList.remove('hide'))
                variantsEl.classList.remove('pad');
            } else {
                arrows.forEach(el => el.classList.add('hide'))
                variantsEl.classList.add('pad');
            }
        }
    }

    maybeHideVariantsArrows();
    window.addEventListener('resize', maybeHideVariantsArrows);

    let maybeShowArrows = () => {
        if (variantsEl) {
            const threshold = 40;

            //up arrow
            if (variantsEl.scrollTop < threshold) {
                arrows[0].classList.remove('show')
            } else {
                arrows[0].classList.add('show')
            }

            //down arrow
            if (variantsEl.scrollHeight - variantsEl.clientHeight - variantsEl.scrollTop < threshold) {
                arrows[1].classList.remove('show')
            } else {
                arrows[1].classList.add('show')
            }
        }
    }

    variantsEl && variantsEl.addEventListener("scroll", maybeShowArrows, { passive: true })
    maybeShowArrows();
}