import "./css/style.scss";
import 'aos/dist/aos.css';
import './css/lightbox.css';
import './css/tiny-slider.css';

import './js/skip-link-focus-fix';
import './js/navigation';
import './js/main';
import './js/header';
import './js/product-lvl-2';
import Tocca from "tocca";
import AOS from "aos";
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import lightbox from "lightbox2/dist/js/lightbox";
import jQuery from "jquery";


function marginTopForPage() {
    let header = document.getElementById('masthead');
    let page = document.getElementById('page');

    // if (window.innerWidth >= 1200) {
    page.style.marginTop = header.clientHeight + "px";
    // } else {
    // page.style.marginTop = 0;
    // }
}

marginTopForPage();


lightbox.option({
    albumLabel: "%1 z %2",
    wrapAround: true
})

AOS.init({
    delay: 50,
    duration: 300,
    // disable: window.aosDisable ?? false
});

//home page - hero slider
let homeBanners = document.querySelector(".home-banners-wrap");
if (homeBanners != null) {
    var mainSlider = tns({
        container: homeBanners,
        mode: "gallery",
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        speed: 1200,
        nav: true,
        touch: true,
        navContainer: ".home-banners-dots",
        controls: true,
        prevButton: ".home-banner-prev",
        nextButton: ".home-banner-next",
    })
}

//home page - products section current product slider
window.addEventListener('load', ev => {
    document.querySelectorAll(".products .inner-grid").forEach(el => {
        let prodImages = el.querySelector(".prod-images .images");
        if (prodImages != null) {
            tns({
                container: prodImages,
                mode: "gallery",
                autoplay: true,
                nav: false,
                controls: false,
                autoplayButtonOutput: false,
                speed: 1200,
                nav: true,
                navContainer: prodImages.nextElementSibling.children[0],
                loop: false,
                rewind: true
            })
        }
    })
})

//product level 2 - photos slider
window.addEventListener('load', ev => {
    let tnsEl = document.querySelector(".photos .photos-wrap");

    if (tnsEl) {
        tns({
            container: tnsEl,
            mode: "gallery",
            autoplay: true,
            nav: false,
            controls: true,
            prevButton: '.photos .controls .prev',
            nextButton: '.photos .controls .next',
            autoplayButtonOutput: false,
            speed: 1200,
            nav: false,
            loop: false,
            rewind: true
        })
    }
})


window.addEventListener('resize', marginTopForPage);
window.addEventListener('orientationchange', marginTopForPage);
window.addEventListener('load', marginTopForPage);