//scroll by section
let sections = document.querySelectorAll('.fs-section');

let scroll = {
    activeSection: 0,
    sectionCount: sections.length - 1,
    isThrottled: false,
    throttleDuration: 300,
    // target: $(sections[0]).position().top
}

function downSection() {
    sections[scroll.activeSection + 1].scrollIntoView({
        behavior: "smooth",
        block: "start"
    })
    ++scroll.activeSection;
}

function upSection() {
    sections[scroll.activeSection - 1].scrollIntoView({
        behavior: "smooth",
        block: "start"
    })
    --scroll.activeSection;
}

let scrollTimeout = null;
// Dirty dirty JQuery
if (document.body.classList.contains("page-template-main")) {
    window.addEventListener('DOMContentLoaded', function () {
        this.window.scrollTo(0, 0);


        // $("body").hammer({ preventDefault: true }).on("swipe", function(event) { 
        //   if (event.gesture.direction == 'up') {
        //     if(scroll.activeSection != sections.length - 1){
        //       downSection();
        //       l('SWIPED UP');
        //     }
        //   } else if (event.gesture.direction == 'down') {
        //     if(scroll.activeSection != 0){
        //       upSection();
        //       l('SWIPED DOWN');
        //     }
        //   }
        // });

        window.addEventListener('scroll', ev => {
            ev.preventDefault();
        })

        window.addEventListener('wheel', event => {
            event.preventDefault();
            scrollTimeout = this.setTimeout(() => {
                let currentSection = sections[scroll.activeSection];
                if (scroll.isThrottled) { return; }

                if (currentSection.classList.contains("allow-scroll")) {
                    if (event.deltaY > 0) {
                        if (window.scrollY + window.innerHeight < currentSection.getBoundingClientRect().bottom + window.scrollY) {
                            window.scrollBy({ top: window.innerHeight * .75, behavior: "smooth" })
                            return;
                        } else {
                            event.preventDefault();
                        }
                    } else {
                        // console.log(currentSection.getBoundingClientRect().top + window.scrollY)
                        if (window.scrollY > (currentSection.getBoundingClientRect().top + window.scrollY)) {
                            window.scrollBy({ top: -1 * (window.innerHeight * .75), behavior: "smooth" })
                            return;
                        } else {
                            event.preventDefault();
                        }
                    }
                }

                event.preventDefault();

                scroll.isThrottled = true;

                setTimeout(function () {
                    scroll.isThrottled = false;
                }, scroll.throttleDuration);

                if (event.deltaY < 0) {

                    console.log(scroll);

                    if (scroll.activeSection === 0) return false;
                    if (scroll.activeSection === 1) {
                        scroll.activeSection = 0;
                        window.scrollTo({ "top": 0, "behavior": "smooth" })
                        return
                    };

                    upSection();

                } else {

                    if (scroll.activeSection >= scroll.sectionCount) return false;
                    downSection();

                }
            }, 100);
        }, {
            passive: false
        });

        window.addEventListener('keydown', function (e) {

            if (e.keyCode == 40 && (scroll.activeSection != sections.length - 1)) {
                e.preventDefault();
                downSection();

            } else if (e.keyCode == 38 && (scroll.activeSection != 0)) {
                e.preventDefault();
                upSection();

            }

        }, {
            passive: false
        });

    }); // end doc ready
}
