// import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import createScrollSnap from 'scroll-snap'


function isElementInViewport(elem) {
    var $elem = jQuery(elem);

    // Get the scroll position of the page.
    var viewportTop = jQuery(window).scrollTop();
    var viewportBottom = viewportTop + jQuery(window).height();

    // Get the position of the element on the page.
    var elemTop = Math.round($elem.offset().top);
    var elemBottom = elemTop + $elem.height();
    return ((elemTop < viewportBottom) && (elemBottom > viewportTop));
}

// Check if it's time to start the animation.
function checkAnimation() {
    var $elem = jQuery('.anim-on-scroll');

    $elem.each(function () {
        // If the animation PHs already been started
        if (jQuery(this).hasClass('start')) return;

        if (isElementInViewport(jQuery(this))) {
            // Start the animation
            jQuery(this).addClass('start');
        }

    })

}

function closeNav() {
    jQuery('.menu-header-container').removeClass('open');
}

function openNav() {
    jQuery('.menu-header-container').addClass('open');
}

function toggleNav() {
    let nav = jQuery('.menu-header-container');
    let pages = jQuery('.right-menu');

    nav.toggleClass('open');
    if (pages.length != 0) {
        if (nav.hasClass('open')) {
            pages.css('z-index', 'auto');
        } else {
            setTimeout(function () {
                pages.css('z-index', '1990');
            }, 300)
        }
    }
}
function toggleSearch(ev) {
    if (ev.target.tagName == "LI") {
        document.querySelector("#float-search").classList.toggle("open");
    }

}

// Capture scroll events
jQuery(window).on('scroll load', function () {
    checkAnimation();
});

jQuery(document).ready(function () {
    var el = document.createElement('div');
    el.className = "mobile-top justify-content-between align-items-center";
    var picture = document.createElement('picture');
    picture.innerHTML = '<img src="' + window.baseUrl + '/wp-content/themes/brodnet_theme/assets/images/common/Ginter_logo_30_lat_wektor_3.svg" alt="Logo firmy" title="Logo firmy">';
    var button = document.createElement('button');
    button.id = 'nav-close';
    var anchor = document.createElement('a');
    anchor.href = window.baseUrl;
    anchor.appendChild(picture);
    var div = document.createElement('div');
    var div2 = document.createElement('div');
    div.appendChild(anchor);
    div2.appendChild(button);
    el.appendChild(div);
    el.appendChild(div2)
    jQuery('#menu-header').prepend(el);

    var body = jQuery("body");

    jQuery('body').on('click', '#nav-toggle, #nav-close', function () {
        toggleNav();
    });
    jQuery('body').on('click', '.search-toggle, #search-close', function (ev) {
        toggleSearch(ev);
    });

    jQuery('.back-to-top').click(function () {
        jQuery('body, html').animate({ scrollTop: 0 }, 1000, 'swing')
    })

    // jQuery('#page').on('swiperight', function (ev, data) {
    //     // debugger
    //     if (data.distance.x > 200) {
    //         if (!jQuery('.menu-aware').hasClass('open')) {
    //             openNav();
    //         } else {
    //             jQuery('.right-menu .menu-wrap').removeClass('open');
    //         }
    //     }
    // })

    // jQuery('#page').on('swipeleft', function (ev, data) {
    //     // debugger
    //     if (data.distance.x > 200) {
    //         // if (!jQuery('.menu-header-container').hasClass('open')) {
    //         // jQuery('.right-menu .menu-wrap').addClass('open');
    //         // } else {
    //         closeNav();
    //         // }
    //     }
    // })

    // jQuery('.mobile-bottom-nav--header').on('swipeup', function (ev, data) {
    //     jQuery(".mobile-bottom-nav").addClass("open");
    //     body.addClass('no-scroll');
    // })

    jQuery('.mobile-bottom-nav--header').on('swipedown', function (ev, data) {
        jQuery(".mobile-bottom-nav").removeClass("open");
        body.removeClass('no-scroll');
    })

    jQuery('.mobile-bottom-nav--header').on('click', function (ev, data) {
        jQuery(".mobile-bottom-nav").toggleClass("open");
        // body.toggleClass('no-scroll')
    })

    let template = document.getElementById('float-search').content;

    if (window.innerWidth < 1200) {
        jQuery('#nav-toggle').parent().prepend(template);
    } else {
        jQuery('#menu-header').append(template);
    }
});

window.addEventListener("load", ev => {
    let closeListMenu = () => document.querySelector('#products .list-col').classList.remove("float-open");
    let place = window.innerWidth < 768 ? "top" : "left-start";
    let up = document.querySelector(".products .list-col .up");
    let down = document.querySelector(".products .list-col .down");
    let close = document.querySelector('#products .list-col .close');

    document.querySelectorAll(".products .list-col .item").forEach(el => {
        el.addEventListener("click", e => {
            let id = el.dataset.id;
            document.querySelector(".products .list-col .item.selected").classList.remove('selected')
            el.classList.add("selected")
            document.querySelector(".inner-grid.active").classList.remove('active');
            document.querySelector(`.inner-grid[data-id="${id}"]`).classList.add('active');
            closeListMenu()
        })
    })

    up && up.addEventListener("click", ev => {
        let list = document.querySelector(".products .list");
        list.scrollBy({ top: -400, behavior: "smooth" });
    })

    down && down.addEventListener("click", ev => {
        let list = document.querySelector(".products .list");
        list.scrollBy({ top: 400, behavior: "smooth" });
    })

    document.querySelectorAll(".products .colors .color").forEach(el => {
        tippy(el, {
            content: `<img src="${el.dataset.popupImage}" class="${el.dataset.popupImage == '' ? "no-image" : ''}"><div class="title">${el.dataset.title}</div>`,
            placement: place,
            arrow: true,
            allowHTML: true
        });
    })

    document.querySelectorAll(".products .prod-tech .box, .tech-boxes .box").forEach(el => {
        tippy(el, {
            content: `<div class="tech-content"><div class="title">${el.dataset.name}</div><div>${el.dataset.text}</div></div>`,
            placement: place,
            arrow: true,
            allowHTML: true
        });
    })


    document.querySelectorAll('.prod-list-toggler').forEach(el => {
        el.addEventListener('click', ev => {
            document.querySelector('#products .list-col').classList.add("float-open")
        })
    })

    close && close.addEventListener('click', ev => {
        closeListMenu()
    })

    let scrollEl = document.querySelector(".products .list");
    let scrollElArrowUp = document.querySelector('.products .up');
    let scrollElArrowDown = document.querySelector('.products .down');
    let maybeHideListArrows = () => {
        scrollElArrowUp.style.opacity = scrollEl.scrollTop == 0 ? 0 : 1;
        scrollElArrowDown.style.opacity = scrollEl.scrollTop + scrollEl.clientHeight == scrollEl.scrollHeight ? 0 : 1;
    }
    scrollEl.addEventListener("scroll", maybeHideListArrows)
    maybeHideListArrows()

})

import "./main-scroller"