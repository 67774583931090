let dY = 0
let lastY = 0;
let scrollThreshold = 200

window.addEventListener('scroll', ev => {
    let dY = window.scrollY - lastY;
    let header = document.querySelector("header#masthead");

    //scrolling down and past header
    if (window.scrollY > scrollThreshold && dY > 0) {
        header.classList.add("slide-up");
    }

    if (dY < 0) {
        header.classList.remove("slide-up");
    }

    lastY = window.scrollY;
})

window.addEventListener('DOMContentLoaded', ev => {
    if (window.scrollY > scrollThreshold) {
        document.querySelector("header#masthead").classList.add("slide-up");
    }
}) 